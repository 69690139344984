import React, { memo, useEffect, useState } from "react";
import "./ProductDetails.scss";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import StoredVariables, { GetSession } from "../../constant/Session";
import "./ProductDetails.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/buttons/Button";
import { useParams } from "react-router-dom";
import { GET, POST } from "../../constant/RequestAuthService";
import { Img_url } from "../../constant";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { DECODE, IsOnline } from "../../constant/Util";
import { UpdateCartApi } from "../home/topOffers/AddCartFunction";
import SavedData from "../../redux/api/cart/SavedData";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { ProductReload } from "../../components/useCustomHooks/useFunctions";
import { currentDate } from "../home/Home";
import { Heading2 } from "../../components/heading/Heading";
import SearchIcon from "@mui/icons-material/Search";
// import ReactImageMagnify from "react-image-magnify";
// import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import ReactImageMagnify from "react-image-magnify";
import useMediaQuery from "@mui/material/useMediaQuery";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { getImageSize } from "react-image-size";

const ProductDetails = () => {
  const { id } = useParams();
  const [Product, setProduct] = useState("");
  const [setPrice] = useState("");
  const [fetch, setFetch] = useState("nofetch");
  const [removed, setRemoved] = useState("");
  const isLogin = GetSession(StoredVariables.username);
  const [text, setText] = useState("");
  const [showNotify, setShowNotify] = useState(false);
  const [relatedProducts, setRelatedProduct] = useState(null);
  const [relLength, setRelLength] = useState(10);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [allRelated, setAllRelated] = useState([]);
  const [large, setLarge] = useState(false);
  const [ads, setAds] = useState(null);

  const [bigImgCont, setBigImgCont] = useState(false);
  ProductReload();

  // console.log(Product);

  const cartItemSend = useSelector((state) => state.cartsReducer);
  const getSavedWishList = useSelector((state) => state.userReducer.wishList);

  const navigate = useNavigate();

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };
  const isUserLogin = GetSession(StoredVariables.username);

  const getAds = async () => {
    const response = await GET("ads");
    // console.log(response);
    if (response.status === 200) {
      const ban = [...response.data];

      const arr2 = [];

      let ind = 0,
        max = ban[0],
        min = ban[0];

      for (let j = 0; j < ban.length; j++) {
        if (max.is_order < ban[j].is_order) {
          max = ban[j];
        }
      }

      for (let i = 0; i < ban.length; i++) {
        for (let k = 0; k < ban.length; k++) {
          if (ban[k] !== null) {
            if (min.is_order > ban[k].is_order) {
              min = ban[k];
              ind = k;
            }
          }
        }
        arr2[i] = min;

        ban[ind] = null;
        min = max;
      }
      setAds(arr2);
    }
  };

  // console.log(ads);

  useEffect(() => {
    getAds();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getProduct(id);
  }, [id]);

  const getProduct = async (id) => {
    setLoading(true);
    const response1 = await GET("home", { id: id });
    if (response1.status === 200) {
      await setProduct(response1.data);
      setLoading(false);
    }
    setLoading(false);
  };

  const isProductAdded = cartItemSend.items.find(
    (product) => product.id === Product.id
  );

  useEffect(() => {
    if (isProductAdded) {
      setProduct({ ...Product, finalQuantity: isProductAdded.finalQuantity });
    } else {
    }
  }, [isProductAdded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getAllProduct();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllProduct = async () => {
    const response1 = await GET("home");
    if (response1.status === 200) {
      // setAllProduct(response1.data);
    }
  };
  const dispatch = useDispatch();
  // console.log(AllProduct.data);

  const deleteCart = async (id) => {
    // if (isUserLogin && IsOnline) {
    await dispatch({ type: "DeleteItems", payload: id });
    setFetch("fetch");
    setTimeout(() => {
      setFetch("");
    }, 300);
    // } else {
    //   toast.error("Please login first");
    //   navigate("/login");
    // }
  };
  useEffect(() => {
    fetch === "fetch" && UpdateCartApi(cartItemSend);
  }, [fetch]); // eslint-disable-line react-hooks/exhaustive-deps

  const addToCart = async (data) => {
    // if (isUserLogin) {
    await dispatch({
      type: "AddItemsFromDetailsPage",
      payload: { ...data, finalQuantity: 1 },
    });
    setFetch("fetch");
    setTimeout(() => {
      setFetch("");
    }, 300);
    // }
    // else {
    //   toast.error("Please login first");
    //   navigate("/login");
    // }
  };

  const isFrontImages = JSON.parse(localStorage.getItem("frontImages"));

  const Increment = (data) => {
    dispatch({ type: "UpdateData", payload: data });
    setFetch("fetch");
    setTimeout(() => {
      setFetch("");
    }, 300);
  };

  const decrement = (id) => {
    // console.log(id);
    dispatch({ type: "DegradeData", payload: id });
    setFetch("fetch");
    setTimeout(() => {
      setFetch("");
    }, 300);
  };

  const handlewishtList = async (data) => {
    if (!isLogin) {
      navigate("/login");
      toast.error("Please login first");
    } else {
      const sendData = {
        product_id: data,
      };

      const response = await POST("product/faviorate", sendData);

      if (response.status === 200) {
        toast.success(response.message, { position: "bottom-left" });
        setRemoved("remove");
        setTimeout(() => {
          setRemoved("");
        }, 300);
      }
      if (response.status === 203) {
        toast.error(response.message, { position: "bottom-left" });
        setRemoved("remove");
        setTimeout(() => {
          setRemoved("");
        }, 300);
      }
    }
  };

  useEffect(() => {
    getWishList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getWishList = async () => {
    const response1 = await GET("product/faviorate");
    if (response1.status === 200) {
      // console.log(response1.data);
      dispatch({ type: "SavedWishList", payload: response1.data });
    }
  };

  useEffect(() => {
    removed === "remove" && getWishList();
  }, [removed]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Product?.description) {
      try {
        setText(DECODE(Product.description));
      } catch {
        setText("");
      }
    }
  }, [Product]);

  const savedSlider = useSelector((state) => state.apiReducer);

  const handleSlider = async () => {
    const response1 = await GET("sliders");
    dispatch({ type: "sliderAd", payload: response1.data });
  };

  useEffect(() => {
    if (
      (savedSlider && savedSlider.slider.length === 0) ||
      currentDate !== savedSlider.date
    ) {
      handleSlider();
    }

    // return () => clearTimeout(getData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log(savedSlider.slider);

  const notifyMe = async () => {
    if (isLogin) {
      const response = await POST("notify/add", { id: Product.id });
      if (response.status === 200) {
        toast.success(response.message);
        getNotificationMessage();
        checkNotify();
      }
    } else {
      toast.error("Please login first");
    }
  };

  useEffect(() => {
    checkNotify();
  }, [Product]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkNotify = async () => {
    if (Product.mapped === null) {
      if (isLogin) {
        const response = await GET("notify/check", { id: Product.id });
        if (response.status === 200) {
          setShowNotify(response.data);
        }
      } else {
        setShowNotify(true);
      }
    }
  };

  // console.log(showNotify);

  const getNotificationMessage = async () => {
    const response = await GET("notify/get");
    if (response.status === 200) {
      // console.log(response.data);
      dispatch({ type: "notifmssg", payload: response.data });
    }
  };

  // console.log(Product);

  useEffect(() => {
    getRelatedProducts();
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const getRelatedProducts = async () => {
    const response = await GET("related", { id: id });
    if (response.status === 200) {
      // console.log("run");
      setAllRelated(response.data);
      setRelatedProduct(response.data);
    }
  };
  // console.log(relatedProducts);

  const viewMore = () => {
    setRelLength(relLength + 10);
  };

  useEffect(() => {
    const filtArr = allRelated.filter((x) =>
      x.name.toLowerCase().includes(search.toLowerCase())
    );
    setRelatedProduct(filtArr);
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps
  // console.log(relatedProducts);
  // console.log(relatedProducts);

  const above768 = useMediaQuery("(min-width:576px)");

  useEffect(() => {
    const getSizeOfimg = async () => {
      const { width, height } = await getImageSize(
        `${Img_url}${Product?.picture?.[0]?.name}`
      );
      if (width < 1000 || height < 1000) {
        setBigImgCont(true);
      }
    };

    getSizeOfimg();
  }, [Product]);

  // console.log(text.replace(/<[^>]+>/g, ""));

  const above1200 = useMediaQuery("(min-width:1200px)");
  return (
    <>
      {loading === true && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="d-flex flex-column">
        <SavedData fetch={fetch} setFetch={setFetch} />
        {Product ? (
          <div className="container mt-3 mb-3 pb-0">
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-sm-6">
                    <div
                      onMouseLeave={() => setLarge(false)}
                      onMouseEnter={() => setLarge(true)}
                      className="img_thumbnail p-4 br_8"
                    >
                      {Product.picture.length ? (
                        <>
                          {above768 === true ? (
                            <ReactImageMagnify
                              {...{
                                smallImage: {
                                  alt: "Wristwatch by Ted Baker London",
                                  isFluidWidth: true,
                                  src: `${Img_url}${Product.picture[0].name}`,
                                },
                                largeImage: {
                                  // src: watchImg1200,
                                  width: 1200,
                                  height: 1800,
                                  src: `${Img_url}${Product.picture[0].name}`,
                                },
                                enlargedImageContainerDimensions: {
                                  width: `${
                                    bigImgCont === true ? "240%" : "100%"
                                  }`,
                                  height: `${
                                    bigImgCont === true ? "240%" : "100%"
                                  }`,
                                },
                              }}
                            />
                          ) : (
                            <Zoom>
                              <img
                                alt="details"
                                src={`${Img_url}${Product.picture[0].name}`}
                                className="img-fluid"
                                style={{ minHeight: "300px" }}
                              />
                            </Zoom>
                          )}
                        </>
                      ) : (
                        <>
                          {isFrontImages ? (
                            above768 === true ? (
                              <ReactImageMagnify
                                {...{
                                  smallImage: {
                                    alt: "Wristwatch by Ted Baker London",
                                    isFluidWidth: true,
                                    src: `${Img_url}${isFrontImages[3].credencial_content}`,
                                  },
                                  largeImage: {
                                    // src: watchImg1200,
                                    width: 1200,
                                    height: 1800,
                                    src: `${Img_url}${isFrontImages[3].credencial_content}`,
                                  },
                                }}
                              />
                            ) : (
                              <Zoom>
                                <img
                                  alt="details"
                                  src={`${Img_url}${isFrontImages[3].credencial_content}`}
                                  style={{
                                    height: "150px",
                                    display: "block",
                                    margin: "10px auto",
                                  }}
                                />
                              </Zoom>
                            )
                          ) : (
                            <CircularProgress color="secondary" />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      zIndex: `${large === true ? -999 : "auto"}`,
                    }}
                    className={` col-sm-6 pt-md-0 pt-4`}
                  >
                    <div className=" ">
                      <p className="product_name ">
                        {Product.name}&nbsp;
                        <span className="fs_15 primary">
                          {" "}
                          {/* ,{Product?.mapped?.pos_product?.Packing} */}
                        </span>
                      </p>
                    </div>
                    {Product.mapped === null && (
                      <div className=" pt-2">
                        {showNotify === true ? (
                          <>
                            <SecondaryButton
                              title="Notify Me when Available"
                              onClick={notifyMe}
                            />
                          </>
                        ) : (
                          <Heading2
                            title="Notified Activated"
                            classNames="primary"
                          />
                        )}

                        {Product?.mapped?.pos_product?.Code && (
                          <>
                            {Product?.company?.name && (
                              <p className="fs_15 fw_500 ">
                                Company :
                                <span
                                  className="fs_12"
                                  style={{ color: `var(--dim_txt)` }}
                                >
                                  {Product.company.name}
                                </span>
                              </p>
                            )}
                            <p className="fs_15 fw_500 mt-1 ">
                              Product Code :
                              <span
                                className="fs_12"
                                style={{ color: `var(--dim_txt)` }}
                              >
                                {Product?.mapped?.pos_product?.Code}
                              </span>
                            </p>

                            {Product?.mapped?.pos_product?.Packing && (
                              <p className="fs_15 fw_500 mt-1">
                                Packing :
                                <span
                                  className="fs_12"
                                  style={{ color: `var(--dim_txt)` }}
                                >
                                  {Product?.mapped?.pos_product?.Packing}
                                </span>
                              </p>
                            )}

                            <p className="fs_15 fw_500 mt-1">
                              Availabilty :
                              <span
                                className="fs_12"
                                style={{ color: `var(--dim_txt)` }}
                              >
                                {Product.availiable_in}
                              </span>
                            </p>
                          </>
                        )}
                      </div>
                    )}
                    {Product?.mapped?.pos_product?.Packing && (
                      <div className=" pt-2">
                        <p className="fs_20 fw_500">
                          Packing&nbsp;:&nbsp;
                          <input
                            type="text"
                            placeholder="price"
                            className="price_input"
                            name="price"
                            value={Product?.mapped?.pos_product?.Packing}
                            onChange={handlePriceChange}
                            readOnly
                          />
                        </p>
                      </div>
                    )}
                    {Product?.company?.name && (
                      <div className=" pt-2">
                        <p className="fs_20 fw_500">
                          Manufacturer/Marketer&nbsp;:&nbsp;
                          <input
                            type="text"
                            placeholder="price"
                            className="price_input"
                            name="price"
                            value={
                              Product?.company?.name &&
                              Product?.company?.name
                                .toLowerCase()
                                .charAt(0)
                                .toUpperCase() + Product?.company?.name.slice(1)
                            }
                            onChange={handlePriceChange}
                            readOnly
                          />
                        </p>
                      </div>
                    )}
                    {Product?.composition?.composition && (
                      <div className="d-flex pt-2 flex-wrap">
                        <div className="d-flex me-1">
                          <p
                            className="fs_20 fw_500 me-1"
                            // style={{ minWidth: "27%" }}
                          >
                            Composition
                          </p>
                          <span>:</span>
                        </div>
                        <p
                          className={`${above1200 && "pe-4"} fs_20 fw_500`}
                          style={{ textAlign: "justify" }}
                        >
                          {Product?.composition?.composition &&
                            Product?.composition?.composition
                              .toLowerCase()
                              .charAt(0)
                              .toUpperCase() +
                              Product?.composition?.composition.slice(1)}
                        </p>
                      </div>
                    )}
                    {Product?.mapped?.pos_product?.BillPrice && (
                      <div className=" pt-2">
                        <p className="fs_20 fw_500">
                          <span
                            className="me-2"
                            style={{ fontSize: "20px", fontWeight: 700 }}
                          >
                            &#8377;&nbsp;
                            {Product?.mapped?.pos_product?.BillPrice}
                          </span>

                          <span
                            className="me-2"
                            style={{
                              textDecoration: "line-through",
                              color: `var(--secondary)`,
                            }}
                          >
                            {Product?.mapped?.pos_product?.Mrp}
                          </span>

                          <span className="off_text_percent">
                            GET{" "}
                            {100 -
                              (
                                Product?.mapped?.pos_product?.BillPrice /
                                Product?.mapped?.pos_product?.Mrp
                              ).toFixed(2) *
                                100}
                            % OFF
                          </span>
                        </p>
                      </div>
                    )}

                    <div className=" d-flex align-items-center flex-wrap mt-2">
                      {!Product?.mapped?.pos_product ||
                      !Product?.mapped?.pos_product?.total_qty ||
                      Product?.mapped?.pos_product.total_qty === "0" ||
                      Product?.mapped?.pos_product.total_qty === 0 ||
                      Product?.mapped?.pos_product.total_qty === "0.00" ? (
                        <>
                          <Button variant="contained" disabled>
                            Not Available
                          </Button>
                        </>
                      ) : (
                        <>
                          {!isProductAdded ? (
                            <div
                              style={{
                                zIndex: `${large === true ? -999 : "auto"}`,
                              }}
                            >
                              <PrimaryButton
                                onClick={() => addToCart(Product)}
                                className="add_btn "
                                title="Add to cart"
                                classNames="me-3 "
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                zIndex: `${large === true ? -999 : "auto"}`,
                              }}
                              className="d-flex"
                            >
                              {/* <GreyButton title="Added " classNames=" me-3 w-100" /> */}
                              <div
                                style={{
                                  zIndex: `${large === true ? -999 : "auto"}`,
                                }}
                                className="d-flex"
                              >
                                <Grid className="d-flex align-items-center">
                                  <IconButton
                                    onClick={() => decrement(Product.id)}
                                    className="bg-opacity-10 quantity-btn wh-30px fw-bold fs-5"
                                  >
                                    -
                                  </IconButton>

                                  <Typography className="px-2 fs-7 fw-bold">
                                    {isProductAdded.finalQuantity}
                                  </Typography>
                                  <IconButton
                                    onClick={() => Increment(isProductAdded)}
                                    className="bg-opacity-10 quantity-btn wh-30px fw-bold fs-5"
                                  >
                                    +
                                  </IconButton>
                                </Grid>
                                <SecondaryButton
                                  onClick={() => deleteCart(Product.id)}
                                  title="Remove from Cart"
                                  classNames=" w-100 me-3"
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      <div
                        style={{
                          zIndex: `${large === true ? -999 : "auto"}`,
                        }}
                      >
                        <IconButton
                          onClick={() => handlewishtList(Product.id)}
                          className="top_oofer_icon"
                        >
                          {getSavedWishList &&
                          getSavedWishList.find(
                            (item) => item.product_id === Product.id
                          ) ? (
                            <FavoriteIcon sx={{ color: `var(--secondary)` }} />
                          ) : (
                            <FavoriteBorderIcon />
                          )}
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </div>
                {text !== "" &&
                  text !== "null" &&
                  text.replace(/<[^>]+>/g, "") !== "null" &&
                  text !== null && (
                    <div className="mt-4">
                      <p className="product_name ">Description</p>
                      {text !== "" && (
                        <div
                          className="footer_txt text-center py-2"
                          dangerouslySetInnerHTML={{
                            __html: text,
                          }}
                        ></div>
                      )}
                    </div>
                  )}
              </div>
              <div
                style={{
                  zIndex: `${large === true ? -999 : "auto"}`,
                }}
                className="col-lg-4 pt-md-0 pt-4"
              >
                {Product?.composition?.composition &&
                  Product?.composition?.composition !== "Not Available" &&
                  Product?.composition?.composition !== "not available" && (
                    <div>
                      <Heading2
                        title={`Alternate Products to ${Product.name}`}
                      />
                      <p className="mb-3">
                        (Same medicine of different Manufacturer/Marketer)
                      </p>
                      <div className="relative">
                        <input
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          style={{ paddingLeft: "15px" }}
                          className="search_input_field"
                          placeholder="Search for your favourite company"
                        />
                        <IconButton className="icon seacrh_icon_input_inside">
                          <SearchIcon className=" pink_icon" />
                        </IconButton>
                      </div>
                      <div className="related_products_div">
                        {relatedProducts &&
                          relatedProducts.length !== 0 &&
                          relatedProducts.slice(0, relLength).map((data, i) => (
                            <div
                              key={i}
                              className="isRead my-2 p-2 br_8  d-flex align-items-center justify-content-between  "
                            >
                              <div
                                className="d-flex align-items-center pointer"
                                style={{ gap: "10px" }}
                                onClick={() =>
                                  navigate(`/productDetails/${data.id}`)
                                }
                              >
                                {/* <div>
                            {data.picture ? (
                              <>
                                <img
                                  src={`${Img_url}${data.picture}`}
                                  // src={servicdata[0].img}
                                  alt="details"
                                  className="img-fluid br_8"
                                  style={{
                                    height: "30px",
                                    width: "55px",
                                    display: "block",
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                {" "}
                                {isFrontImages ? (
                                  <img
                                    src={`${Img_url}${isFrontImages[3].credencial_content}`}
                                    className="img-fluid  br_8"
                                    alt="top_offer"
                                    style={{
                                      height: "30px",
                                      width: "55px",
                                      display: "block",
                                    }}
                                  />
                                ) : (
                                  <CircularProgress color="secondary" />
                                )}
                              </>
                            )}
                          </div> */}

                                <div>
                                  <p className="order_item_main_head fs_12 fw_700">
                                    {data.name}
                                  </p>
                                  <div
                                    className="d-flex align-items-center flex-wrap"
                                    style={{ gap: "4px" }}
                                  >
                                    <p className="order_item_main_head fs_12">
                                      {data.BillPrice}
                                    </p>
                                    <span
                                      className="fs_12 fw_700"
                                      style={{
                                        textDecoration: "line-through",
                                        color: `var(--secondary)`,
                                      }}
                                    >
                                      {data.Mrp}
                                    </span>
                                    {data?.BillPrice !== null &&
                                      data?.Mrp !== null && (
                                        <span className="off_text_percent fs_12 fw_700">
                                          GET{" "}
                                          {100 -
                                            (
                                              data?.BillPrice / data?.Mrp
                                            ).toFixed(2) *
                                              100}
                                          % OFF
                                        </span>
                                      )}
                                  </div>
                                </div>
                              </div>
                              <IconButton
                                onClick={() => handlewishtList(data.id)}
                                className="top_oofer_icon"
                              >
                                {getSavedWishList &&
                                getSavedWishList.find(
                                  (item) => item.product_id === data.id
                                ) ? (
                                  <FavoriteIcon
                                    sx={{ color: `var(--secondary)` }}
                                  />
                                ) : (
                                  <FavoriteBorderIcon />
                                )}
                              </IconButton>
                            </div>
                          ))}
                        {relLength < relatedProducts.length ? (
                          <SecondaryButton
                            title="View More"
                            classNames="w-100"
                            onClick={viewMore}
                          />
                        ) : (
                          <div className="d-flex align-items-center justify-content-center">
                            <h5 className="primary fw_700">
                              {" "}
                              No more Products
                            </h5>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                {ads && typeof ads === "object" ? (
                  <>
                    {ads.length === 0 ? (
                      ""
                    ) : (
                      <Carousel
                        additionalTransfrom={0}
                        arrows
                        autoPlay
                        autoPlaySpeed={4000}
                        className=""
                        containerclassName="container-with-dots"
                        dotListclassName=""
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemclassName=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        pauseOnHover
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={{
                          bdesktop: {
                            breakpoint: {
                              max: 2600,
                              min: 1440,
                            },
                            items: 1,
                            partialVisibilityGutter: 40,
                          },
                          desktop: {
                            breakpoint: {
                              max: 1440,
                              min: 1024,
                            },
                            items: 1,
                            partialVisibilityGutter: 40,
                          },
                          mobile: {
                            breakpoint: {
                              max: 464,
                              min: 0,
                            },
                            items: 1,
                            partialVisibilityGutter: 30,
                          },
                          bigTablet: {
                            breakpoint: {
                              max: 1024,
                              min: 769,
                            },
                            items: 1,
                            partialVisibilityGutter: 30,
                          },
                          tablet: {
                            breakpoint: {
                              max: 768,
                              min: 464,
                            },
                            items: 1,
                            partialVisibilityGutter: 30,
                          },
                        }}
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={false}
                        shouldResetAutoplay
                        // showDots
                        sliderclassName=""
                        slidesToSlide={1}
                        swipeable
                      >
                        {ads.map((data, i) => (
                          <div
                            key={i}
                            className="d-flex align-items-start justify-content-center mt-4"
                            style={{
                              width: "100%",
                            }}
                          >
                            <img
                              style={{ height: "200px" }}
                              src={`${Img_url}${
                                data?.image
                                // Math.floor(Math.random() * savedSlider.slider.length)
                              }`}
                              alt="img"
                              className="img-fluid shadow-lg br_8 w-100"
                            />
                          </div>
                        ))}
                      </Carousel>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
    </>
  );
};

export default memo(ProductDetails);

// session ho to checkin varn login
