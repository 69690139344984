import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  CartItemSend,
  IsOnline,
  SearchProduct,
  UserLogin,
} from "../../constant/Util";
import { UpdateCartApi } from "../../pages/home/topOffers/AddCartFunction";
import { OutlineButton, PrimaryButton } from "../buttons/Button";
import Del from "../../assets/images/Del.png";
import { Img_url } from "../../constant";
import SavedData from "../../redux/api/cart/SavedData";
import { ProductReload } from "../useCustomHooks/useFunctions";
import { Heading2 } from "../../components/heading/Heading";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import StoredVariables, { GetSession } from "../../constant/Session";

const ProductSeachDropDown = ({ search, setClick }) => {
  ProductReload();
  const [fetch, setFetch] = useState("nofetch");
  const allComingProduct = SearchProduct();
  const [allProduct, setAllProduct] = useState([]);
  const isUserLogin = UserLogin();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartItemSend = CartItemSend();
  const checkPayloadExists = (list, searchPayload) => {
    const stringifyPayload = JSON.stringify(searchPayload);
    const existPayload = list.find(
      (item) => JSON.stringify(item) === stringifyPayload
    );
    return !!existPayload;
  };
  const addRecentSearch = (searchData) => {
    setClick(true);
    let list = JSON.parse(localStorage.getItem("recentSearchList")) || [];
    if (checkPayloadExists(list, searchData)) return;
    list.unshift(searchData);
    if (list.length > 6) list.pop();

    localStorage.setItem("recentSearchList", JSON.stringify(list));
  };
  const addToCart = async (data) => {
    // console.log(data);
    // if (isUserLogin && IsOnline) {
    await dispatch({
      type: "AddItems",
      payload: data,
    });
    setFetch("fetch");
    setTimeout(() => {
      setFetch("");
    }, 300);
    // } else {
    //   toast.error("Please login first");
    //   navigate("/login");
    // }
  };

  const deleteCart = async (id) => {
    // if (isUserLogin && IsOnline) {
    await dispatch({ type: "DeleteItems", payload: id });
    setFetch("fetch");
    setTimeout(() => {
      setFetch("");
    }, 300);
    // } else {
    //   toast.error("Please login first");
    //   navigate("/login");
    // }
  };

  const decrement = async (id) => {
    dispatch({ type: "DegradeData", payload: id });
    await dispatch({ type: "IsProductAdded" });

    setFetch("fetch");
    setTimeout(() => {
      setFetch("");
    }, 300);
  };

  const Increment = async (id) => {
    dispatch({ type: "UpdateData", payload: id });
    await dispatch({ type: "IsProductAdded" });
    setFetch("fetch");
    setTimeout(() => {
      setFetch("");
    }, 300);
  };

  useEffect(() => {
    fetch === "fetch" && UpdateCartApi(cartItemSend);
  }, [fetch]); // eslint-disable-line react-hooks/exhaustive-deps

  const isFrontImages = JSON.parse(localStorage.getItem("frontImages"));

  const loading = useSelector((state) => state.cartsReducer.loading);

  const isLogin = GetSession(StoredVariables.username);

  const routeToMoreDetails = () => {
    if (isLogin) {
      navigate("/moreDetails");
    } else {
      navigate("/login");
      toast.error("Please login First");
    }
  };

  useEffect(() => {
    if (allComingProduct && search) {
      const allsearchedProducts = structuredClone(allComingProduct);
      let arr = "";
      const result = allsearchedProducts.map((data) => ({
        ...data,
        label: "",
      }));
      arr = search.split(" ");
      for (let item of result) {
        let label = item.name;
        for (let item of arr) {
          label = label.replace(
            item.toUpperCase(),
            `<span class="search-term">${item.toUpperCase()}</span>`
          );
        }
        // ``
        item.label = label;
      }

      setAllProduct(result);
    }
  }, [search, allComingProduct]);
  const handleNavigation = (value) => {
    localStorage.setItem("recentSearch", JSON.stringify(value));
    addRecentSearch(value);

    navigate(`/productDetails/${value.id}`);
  };
  return (
    <>
      <SavedData fetch={fetch} setFetch={setFetch} />

      {loading ? (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      ) : (
        <>
          {allProduct ? (
            <>
              <div className="d-flex align-items-center justify-content-end">
                {allProduct.length === 0 ? (
                  <>
                    <OutlineButton
                      title="View All"
                      onClick={() => navigate("/products")}
                    />
                  </>
                ) : (
                  <>
                    <OutlineButton
                      title="View more"
                      onClick={() => navigate("/product")}
                    />
                  </>
                )}
              </div>

              {allProduct.length === 0 ? (
                <div className="mt-3">
                  <Heading2 title="No Products Found" />
                  <div className="d-flex align-items-center  flex-wrap">
                    <Heading2
                      title="Provide more details about Product"
                      classNames="mt-2 primary"
                    />
                    <IconButton
                      onClick={
                        routeToMoreDetails
                        // () => navigate("/moreDetails")
                      }
                      className="mt-2 ms-2"
                    >
                      <KeyboardTabIcon sx={{ color: `var(--secondary)` }} />
                    </IconButton>
                    {/* <PrimaryButton
                      classNames="mt-2"
                      title="Next"
                      onClick={() => navigate("/products")}
                    /> */}
                  </div>
                </div>
              ) : (
                <>
                  {allProduct.map((data, i) => (
                    <div
                      key={i}
                      onClick={() => handleNavigation(data)}
                      style={{ gap: "15px", cursor: "pointer" }}
                    >
                      <div className="order_main_div mt-3 d-flex align-items-start justify-content-between flex-wrap p-2">
                        <div className="d-flex align-items-center">
                          {data.picture !== null ? (
                            <img
                              src={`${Img_url}${data.picture}`}
                              alt="top_offer"
                              width="80px"
                            />
                          ) : (
                            <>
                              {isFrontImages ? (
                                <img
                                  src={`${Img_url}${isFrontImages[3].credencial_content}`}
                                  alt="top_offer"
                                  width="80px"
                                />
                              ) : (
                                <CircularProgress color="secondary" />
                              )}
                            </>
                          )}
                          <div>
                            <p
                              className="order_item_main_head pt-2"
                              dangerouslySetInnerHTML={{ __html: data.label }}
                            ></p>
                            {data?.Mrp && (
                              <p className="  primary_col fs_14 fw_600 mt-1">
                                Rs {data?.Mrp}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className=" my-auto">
                          {!data?.Mrp ||
                          !data?.total_qty ||
                          data.total_qty === "0" ||
                          data.total_qty === 0 ||
                          data.total_qty === "0.00" ? (
                            <>
                              <Button variant="contained" disabled>
                                Not Available
                              </Button>
                            </>
                          ) : (
                            <>
                              {!cartItemSend.items.find(
                                (product) =>
                                  product.id === data.id ||
                                  product.id === data?.mapped?.id
                              ) ? (
                                <PrimaryButton
                                  onClick={() => addToCart(data)}
                                  title="Add To Cart"
                                  classNames="  w-100"
                                />
                              ) : (
                                <div className="d-flex">
                                  <Grid className="d-flex align-items-center">
                                    <IconButton
                                      onClick={() => decrement(data.id)}
                                      className="bg-opacity-10 quantity-btn wh-30px fw-bold fs-5"
                                    >
                                      -
                                    </IconButton>
                                    <Typography className="px-2 fs-7 fw-bold">
                                      {data.finalQuantity}
                                    </Typography>
                                    <IconButton
                                      onClick={() => Increment(data)}
                                      className="bg-opacity-10 quantity-btn wh-30px fw-bold fs-5"
                                    >
                                      +
                                    </IconButton>
                                  </Grid>

                                  <IconButton
                                    onClick={() => deleteCart(data.id)}
                                  >
                                    <img src={Del} alt="delete" width="20px" />
                                  </IconButton>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-center">
                <CircularProgress color="secondary" />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProductSeachDropDown;
